import React, { useState } from 'react';
import { SignedIn, SignedOut, useAuth } from '@clerk/nextjs';
import Link from 'next/link';
import { getTwitchUrl } from '../lib/api';
import TestimonialRow from './TestimonialRow';
import { heroTestimonials } from '../data/heroTestimonials';
import Image from 'next/image';

const Hero = () => {
  const [showForm, setShowForm] = useState(false);
  const { isSignedIn } = useAuth();

  const handleTwitchAuth = async () => {
    if (!isSignedIn) {
      window.location.href = '/sign-in?ref=hero';
      return;
    }

    //if the user is signed in, redirect to /twitch-bot
    window.location.href = '/twitch-bot';
    return;
  };

  return (
    <div className="relative mt-4 mx-6 rounded-2xl overflow-hidden min-h-[calc(100vh-theme(spacing.4)-theme(spacing.16))]">
      {/* Use next/image for background */}
      <Image
        src="https://storage.googleapis.com/saved_media_assets/landing-background.avif"
        alt="Background"
        layout="fill"
        objectFit="cover"
        quality={100}
        priority
        className="-z-10"
      />
      
      {/* Content container with responsive padding */}
      <div className="relative z-0 flex flex-col items-center text-center w-full px-4 sm:px-6 md:px-8 py-12 md:py-16 lg:py-20 h-full justify-between">
        {/* Main content */}
        <div className="flex flex-col items-center w-full mb-12">
          <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-8xl font-bold mb-6 sm:mb-8 md:mb-12 lg:mb-16 leading-tight">
            <div className="text-white"> 
              The Clipping Bot
            </div>
          </h1>
          <p className="text-white mb-6 sm:mb-8 md:mb-10 tracking-wide text-sm sm:text-lg md:text-2xl lg:text-3xl max-w-3xl">
            Saved is the Twitch bot that clips & edits 
            <br />
            highlights while you stream
          </p>

          <div className="flex flex-col sm:flex-row gap-4 items-center justify-center mb-8 sm:mb-12 md:mb-16 w-full max-w-md sm:max-w-lg">
            <button
              onClick={handleTwitchAuth}
              className="w-full sm:w-auto px-6 py-3 sm:px-8 sm:py-4 bg-white hover:bg-gray-100 text-black rounded-lg transition-all duration-200 text-base font-bold flex items-center justify-center"
            >
              <svg 
                viewBox="0 0 24 24" 
                className="w-5 h-5 mr-2 fill-current"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z"/>
              </svg>
              Add to Chat
            </button>
            <button 
              onClick={() => {
                document.getElementById('features')?.scrollIntoView({ 
                  behavior: 'smooth'
                });
              }}
              className="w-full sm:w-auto px-6 py-3 sm:px-8 sm:py-4 text-white bg-black hover:bg-gray-900 rounded-lg transition-all duration-200 text-base font-bold"
            >
              Learn more
            </button>
          </div>
        </div>
        
        {/* Testimonial Row */}
        <div className="w-full sm:w-full md:w-max">
          <TestimonialRow 
            testimonials={heroTestimonials} 
            speed={1.0}
            initialOffset={20}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;