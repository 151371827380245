import React from 'react';

interface SavedLogoProps {
  className?: string;
  width?: number;
  height?: number;
}

const SavedLogo: React.FC<SavedLogoProps> = ({ 
  className = '', 
  width = 400, 
  height = 400
}) => {
  return (
    <div className={`${className} relative`} style={{ width, height }}>
      {/* Logo with subtle glow */}
      <div className="w-full h-full relative">
        {/* Subtle glow effect */}
        <div 
          className="absolute inset-0 rounded-lg blur-xl opacity-30"
          style={{
            background: 'linear-gradient(135deg, rgba(0,119,255,0.4) 0%, rgba(255,118,241,0.4) 100%)',
            transform: 'scale(0.9)',
          }}
        />
        
        {/* Actual logo */}
        <img 
          src="https://storage.googleapis.com/saved_media_assets/SavedLogo2Square.png" 
          alt="Saved.gg Logo" 
          className="w-full h-full object-contain relative z-10"
        />
      </div>
    </div>
  );
};

export default SavedLogo; 