"use client";

import React, { useState, useEffect } from 'react';
import MainLayout from '@/layouts/MainLayout';
import Hero from '@/components/Hero';
import Features from '@/components/Features';
import TestimonialWall from '@/components/TestimonialWall';
import BrowserWarningPopup from '@/components/BrowserWarningPopup';
import useBrowserDetection from '@/hooks/useBrowserDetection';
import CallToAction from '@/components/CallToAction';

const Home = () => {
  const { isChrome, isBrowserDetected, hasWarningBeenDismissed } = useBrowserDetection();
  const [showBrowserWarning, setShowBrowserWarning] = useState(false);
  
  // Show browser warning if not using Chrome and warning hasn't been dismissed
  useEffect(() => {
    if (isBrowserDetected && !isChrome && !hasWarningBeenDismissed) {
      // Reduced delay to improve LCP
      const timer = setTimeout(() => {
        setShowBrowserWarning(true);
      }, 500);
      
      return () => clearTimeout(timer);
    }
  }, [isBrowserDetected, isChrome, hasWarningBeenDismissed]);

  const closeBrowserWarning = () => {
    setShowBrowserWarning(false);
    // Store in localStorage to avoid showing again in this session
    localStorage.setItem('browser-warning-dismissed', 'true');
  };

  return (
    <MainLayout fullWidthHero={true}>
      {/* Hero section without container constraints */}
      <div className="mt-4">
        <Hero />
      </div>
      
      {/* Rest of content with container */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <main className="flex flex-col justify-between items-stretch">
          <div id="features" className="">
            <Features />
          </div>
          <div id="testimonials">
            <TestimonialWall />
          </div>
          <div className="py-0">
            <CallToAction />
          </div>
        </main>
      </div>
      
      {/* Browser Warning Popup - Specifically for Twitch auth issues */}
      <BrowserWarningPopup 
        isOpen={showBrowserWarning} 
        onClose={closeBrowserWarning} 
      />
    </MainLayout>
  );
};

export default Home;