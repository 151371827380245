"use client";

import React, { useRef, useState, useEffect } from 'react';
import { Testimonial } from '../lib/api/types';

interface TestimonialRowProps {
  testimonials: Testimonial[];
  speed: number;
  initialOffset?: number;
}

// Simple testimonial card specifically for the horizontal row
const HorizontalTestimonialCard: React.FC<{ testimonial: Testimonial; isMobile?: boolean }> = ({ testimonial, isMobile }) => {
  return (
    <div className={`flex-shrink-0 bg-white border-2 border-gray-300 rounded-lg p-6 shadow-md mx-0 md:mx-3 lg:mx-3 lg:w-[300px] md:w-[270px] h-[200px] flex flex-col justify-between`}>
      <p className="text-black text-sm font-medium leading-relaxed mb-4 line-clamp-4 text-left">{testimonial.quote}</p>
      <div className="flex items-center">
        <div className="flex-shrink-0">
          {testimonial.avatar ? (
            <img 
              src={testimonial.avatar} 
              alt={testimonial.name}
              className="w-8 h-8 rounded-full object-cover"
            />
          ) : (
            <div className="w-8 h-8 rounded-full bg-gray-200 flex items-center justify-center text-black">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"/>
                <circle cx="12" cy="7" r="4"/>
              </svg>
            </div>
          )}
        </div>
        <div className="ml-3">
          <h4 className="text-black font-semibold text-sm text-left">{testimonial.name}</h4>
          <p className="text-gray-600 text-xs text-left">{testimonial.company}</p>
        </div>
      </div>
    </div>
  );
};

const TestimonialRow: React.FC<TestimonialRowProps> = ({
  testimonials,
  speed,
  initialOffset = 0,
}) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const firstRowRef = useRef<HTMLDivElement>(null);
  const secondRowRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [rowWidth, setRowWidth] = useState(0);
  const [animationReady, setAnimationReady] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const animationRef = useRef<number | null>(null);
  const scrollPositionRef = useRef(0);
  const initialPositionSet = useRef(false);
  const [isMobile, setIsMobile] = useState(false);

  // Create a single set of testimonials that will be duplicated in the DOM
  const testimonialSet = [...testimonials];
  
  useEffect(() => {
    // Function to calculate and set row width
    const calculateWidth = () => {
      if (rowRef.current) {
        // Get the exact width of the content
        const exactWidth = rowRef.current.scrollWidth;
        setRowWidth(exactWidth);
        
        // Set initial position based on initialOffset if not already set
        if (!initialPositionSet.current && initialOffset > 0) {
          const initialPos = (initialOffset / 100) * exactWidth;
          scrollPositionRef.current = -initialPos;
          if (firstRowRef.current && secondRowRef.current) {
            firstRowRef.current.style.transform = `translateX(${-initialPos}px)`;
            secondRowRef.current.style.transform = `translateX(${-initialPos}px)`;
          }
          initialPositionSet.current = true;
        }
        
        // Set animation ready and make visible with minimized delay
        requestAnimationFrame(() => {
          setAnimationReady(true);
          requestAnimationFrame(() => {
            setIsVisible(true);
          });
        });
      }
    };

    // Calculate initially
    calculateWidth();
    
    // Recalculate on window resize
    window.addEventListener('resize', calculateWidth);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', calculateWidth);
    };
  }, [testimonials, initialOffset]);

  // Implement smooth scrolling animation with requestAnimationFrame
  useEffect(() => {
    if (!animationReady || !rowWidth) return;
    
    let startTime: number | null = null;
    const totalDistance = rowWidth;
    // Speed is pixels per second
    const pixelsPerSecond = speed * 40; 
    let lastTimestamp = 0;
    
    const step = (timestamp: number) => {
      if (!startTime) startTime = timestamp;
      
      if (isHovered) {
        // If hovered, just request the next frame without updating position
        animationRef.current = requestAnimationFrame(step);
        return;
      }
      
      // Calculate time elapsed since last frame
      const deltaTime = lastTimestamp ? timestamp - lastTimestamp : 0;
      lastTimestamp = timestamp;
      
      // Only update if we have a valid deltaTime (prevents jumps after tab switch)
      if (deltaTime > 0 && deltaTime < 100) {
        // Calculate how many pixels to move based on elapsed time
        const pixelsToMove = (deltaTime / 1000) * pixelsPerSecond;
        
        // Calculate new position (moving left)
        scrollPositionRef.current -= pixelsToMove;
        
        // Reset position when we've moved a full row width to create seamless loop
        if (Math.abs(scrollPositionRef.current) >= totalDistance) {
          scrollPositionRef.current = scrollPositionRef.current % totalDistance; // Use modulo for smooth looping
          startTime = timestamp; // Reset start time
        }
        
        // Apply transform directly to DOM elements for better performance
        if (firstRowRef.current && secondRowRef.current) {
          firstRowRef.current.style.transform = `translateX(${scrollPositionRef.current}px)`;
          secondRowRef.current.style.transform = `translateX(${scrollPositionRef.current}px)`;
        }
      }
      
      animationRef.current = requestAnimationFrame(step);
    };
    
    // Start the animation
    animationRef.current = requestAnimationFrame(step);
    
    // Cleanup
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [animationReady, rowWidth, isHovered, speed]);

  // Mobile swipeable version
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(0);
  const touchEndX = useRef(0);
  
  // Handle swipe gestures
  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };
  
  const handleTouchMove = (e: React.TouchEvent) => {
    touchEndX.current = e.touches[0].clientX;
  };
  
  const handleTouchEnd = () => {
    const swipeDistance = touchEndX.current - touchStartX.current;
    const minSwipeDistance = 50;
    
    if (swipeDistance > minSwipeDistance) {
      // Swipe right - go to previous
      setCurrentIndex(prev => (prev > 0 ? prev - 1 : testimonials.length - 1));
    } else if (swipeDistance < -minSwipeDistance) {
      // Swipe left - go to next
      setCurrentIndex(prev => (prev < testimonials.length - 1 ? prev + 1 : 0));
    }
  };

  // Desktop version - continuous horizontal scroll
  const renderDesktopView = () => {
    return (
      <div
        className="relative overflow-hidden w-full py-4"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      > 
        {/* Container for the infinite scroll effect */}
        <div 
          className={`relative w-full transition-opacity duration-500 ease-in-out ${isVisible ? 'opacity-100' : 'opacity-0'}`}
        >
          {/* Container for the infinite scroll effect */}
          <div className="relative w-full" ref={rowRef}>
            {/* First copy */}
            <div
              ref={firstRowRef}
              className="inline-flex"
              style={{
                willChange: 'transform',
                transition: isHovered ? 'transform 0.2s ease-out' : 'none',
              }}
            >
              {testimonialSet.map((testimonial, index) => (
                <HorizontalTestimonialCard
                  key={`${testimonial.id}-${index}-main`}
                  testimonial={testimonial}
                />
              ))}
            </div>
            
            {/* Second copy - positioned to create a seamless loop */}
            <div
              ref={secondRowRef}
              className="inline-flex"
              style={{
                position: 'absolute',
                left: rowWidth,
                willChange: 'transform',
                transition: isHovered ? 'transform 0.2s ease-out' : 'none',
              }}
            >
              {testimonialSet.map((testimonial, index) => (
                <HorizontalTestimonialCard
                  key={`${testimonial.id}-${index}-secondary`}
                  testimonial={testimonial}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Mobile version - swipeable cards
  const renderMobileView = () => {
    return (
      <div 
        className="relative w-full py-4"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {/* Card container */}
        <div className="relative w-full h-[200px] rounded-lg">
          {testimonials.map((testimonial, index) => (
            <div 
              key={testimonial.id}
              className={`absolute inset-0 transition-all duration-300 ${
                index === currentIndex 
                  ? 'opacity-100 transform-none z-10' 
                  : 'opacity-0 scale-95 z-0'
              }`}
            >
              <HorizontalTestimonialCard testimonial={testimonial} />
            </div>
          ))}
        </div>
        
        {/* Pagination indicators */}
        <div className="flex justify-center mt-6 gap-2">
          {testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-2 h-2 rounded-full transition-all ${
                index === currentIndex 
                  ? 'bg-white w-4' 
                  : 'bg-gray-200'
              }`}
              aria-label={`Go to testimonial ${index + 1}`}
            />
          ))}
        </div>
      </div>
    );
  };

  // Add this useEffect to handle screen size changes
  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < 768; // 768px is the md breakpoint in Tailwind
      if (mobile !== isMobile) {
        setIsMobile(mobile);
        // Reset states when view changes
        setAnimationReady(false);
        setIsVisible(false);
        scrollPositionRef.current = 0;
        initialPositionSet.current = false;
        setCurrentIndex(0);
        
        // Reinitialize after a short delay
        setTimeout(() => {
          setAnimationReady(true);
          setIsVisible(true);
        }, 100);
      }
    };

    // Initial check
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  return (
    <div className="w-full">
      <div className="hidden md:block">
        {renderDesktopView()}
      </div>
      <div className="block md:hidden">
        {renderMobileView()}
      </div>
    </div>
  );
};

export default TestimonialRow; 