import React from 'react';
import Link from 'next/link';
import { SignedIn, SignedOut, useAuth } from '@clerk/nextjs';
import { usePathname } from 'next/navigation';
import SavedBigLogo from './SavedBigLogo';

const CallToAction = () => {
  const pathname = usePathname();
  const { isSignedIn } = useAuth();

  const handleTwitchAuth = async () => {
    if (!isSignedIn) {
      window.location.href = '/sign-in?redirect=/twitch-bot';
      return;
    }

    window.location.href = '/twitch-bot';
    return;
  };

  return (
    <div className="container mx-auto px-4 py-16 sm:py-24 relative">
      <div className="bg-[#0B0B0C] w-full relative overflow-hidden rounded-xl max-w-3xl mx-auto">
        {/* Background with subtle gradient and pattern */}
        <div className="absolute inset-0">
          <div 
            className="absolute inset-0 bg-transparent"
          />
          {/* Subtle grid pattern with fade to transparency */}
          <div className="absolute inset-0 opacity-5">
            <div className="relative w-full h-full overflow-hidden">
              <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <pattern id="cta-grid" width="40" height="40" patternUnits="userSpaceOnUse">
                    <path d="M 40 0 L 0 0 0 40" fill="none" stroke="white" strokeWidth="1" />
                  </pattern>
                  <linearGradient id="fade-left" x1="0%" y1="50%" x2="10%" y2="50%">
                    <stop offset="0%" stopColor="white" stopOpacity="0" />
                    <stop offset="100%" stopColor="white" stopOpacity="1" />
                  </linearGradient>
                  <linearGradient id="fade-right" x1="100%" y1="50%" x2="90%" y2="50%">
                    <stop offset="0%" stopColor="white" stopOpacity="0" />
                    <stop offset="100%" stopColor="white" stopOpacity="1" />
                  </linearGradient>
                  <linearGradient id="fade-top" x1="50%" y1="0%" x2="50%" y2="10%">
                    <stop offset="0%" stopColor="white" stopOpacity="0" />
                    <stop offset="100%" stopColor="white" stopOpacity="1" />
                  </linearGradient>
                  <linearGradient id="fade-bottom" x1="50%" y1="100%" x2="50%" y2="90%">
                    <stop offset="0%" stopColor="white" stopOpacity="0" />
                    <stop offset="100%" stopColor="white" stopOpacity="1" />
                  </linearGradient>
                  <mask id="edge-fade-mask">
                    <rect width="100%" height="100%" fill="white" />
                    <rect x="0" y="0" width="10%" height="100%" fill="url(#fade-left)" />
                    <rect x="90%" y="0" width="10%" height="100%" fill="url(#fade-right)" />
                    <rect x="0" y="0" width="100%" height="10%" fill="url(#fade-top)" />
                    <rect x="0" y="90%" width="100%" height="10%" fill="url(#fade-bottom)" />
                  </mask>
                </defs>
                <rect width="100%" height="100%" fill="url(#cta-grid)" mask="url(#edge-fade-mask)" />
              </svg>
            </div>
          </div>
        </div>
        
        {/* Content container */}
        <div className="relative z-10 flex flex-col items-center justify-center p-8 sm:p-12 md:p-16">
          {/* Logo centered at the top */}
          <div className="mb-8 sm:mb-12 transform scale-90 sm:scale-100">
            <SavedBigLogo width={220} height={220} />
          </div>
          
          {/* Heading text */}
          <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-8 sm:mb-10 text-center leading-tight">
            Try Saved Now
          </h2>
          
          {/* Button styled like Cursor's */}
          <button 
            onClick={handleTwitchAuth}
            className="w-full max-w-[280px] flex items-center justify-center bg-white hover:bg-gray-100 text-[#0B0B0C] py-3 px-6 sm:px-8 rounded-md transition-all duration-300 shadow-lg"
          >
            <svg 
              viewBox="0 0 24 24" 
              className="w-4 h-4 sm:w-5 sm:h-5 mr-2 sm:mr-3 flex-shrink-0 fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M11.571 4.714h1.715v5.143H11.57zm4.715 0H18v5.143h-1.714zM6 0L1.714 4.286v15.428h5.143V24l4.286-4.286h3.428L22.286 12V0zm14.571 11.143l-3.428 3.428h-3.429l-3 3v-3H6.857V1.714h13.714Z"/>
            </svg>
            <span className="font-semibold tracking-wide text-md sm:text-md whitespace-nowrap">Add to Chat</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;