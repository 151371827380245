"use client";

import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoClose } from 'react-icons/io5';
import { FaChrome, FaTwitch } from 'react-icons/fa';
import useBrowserDetection from '../hooks/useBrowserDetection';

interface BrowserWarningPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const BrowserWarningPopup: React.FC<BrowserWarningPopupProps> = ({ isOpen, onClose }) => {
  const { browserType } = useBrowserDetection();
  
  // Get browser-specific message
  const getBrowserMessage = () => {
    switch (browserType) {
      case 'firefox':
        return "We've noticed Twitch login fails on Firefox. If you have this issue, please try using Chrome.";
      default:
        return "We're not sure if Twitch login will work on non-Chrome browsers. If you experience any issues, please let us know!";
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed bottom-8 right-8 z-[9999] max-w-sm"
          initial={{ y: '100%', opacity: 0 }}
          animate={{ y: '0%', opacity: 1 }}
          exit={{ y: '100%', opacity: 0 }}
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        >
          <div className="bg-[#1A1A1F] shadow-lg rounded-lg overflow-hidden border border-[#2A2A30]">
            <div className="bg-[#FF7A00] px-4 py-3 flex justify-between items-center">
              <div className="flex items-center">
                <FaTwitch className="text-white text-lg mr-2" />
                <h3 className="font-medium text-white text-sm">Browser Compatibility</h3>
              </div>
              <button 
                onClick={onClose}
                className="text-white hover:text-gray-200 transition-colors"
                aria-label="Close"
              >
                <IoClose size={20} />
              </button>
            </div>
            <div className="px-4 py-3">
              <p className="text-gray-300 text-sm">
                {getBrowserMessage()}
              </p>
              <div className="flex justify-end mt-3">
                <a 
                  href="https://www.google.com/chrome/" 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="bg-[#FF7A00] text-white px-3 py-1.5 rounded text-xs font-medium hover:bg-[#E56E00] transition-colors mr-2"
                >
                  Get Chrome
                </a>
                <button
                  onClick={onClose}
                  className="bg-[#2A2A30] text-gray-300 border border-[#3A3A40] px-3 py-1.5 rounded text-xs font-medium hover:bg-[#3A3A40] transition-colors"
                >
                  Dismiss
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BrowserWarningPopup; 