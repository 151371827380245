"use client";

import { useState, useEffect } from 'react';

type BrowserType = 'chrome' | 'firefox' | 'safari' | 'edge' | 'ie' | 'opera' | 'other' | null;

export const useBrowserDetection = (): { 
  browserType: BrowserType;
  isChrome: boolean;
  isBrowserDetected: boolean;
  hasWarningBeenDismissed: boolean;
} => {
  const [browserType, setBrowserType] = useState<BrowserType>(null);
  const [isBrowserDetected, setIsBrowserDetected] = useState(false);
  const [hasWarningBeenDismissed, setHasWarningBeenDismissed] = useState(false);

  useEffect(() => {
    // Only run on client-side
    if (typeof window !== 'undefined') {
      // Check if warning has been dismissed
      const warningDismissed = localStorage.getItem('browser-warning-dismissed') === 'true';
      setHasWarningBeenDismissed(warningDismissed);
      
      // Detect browser type to show appropriate Twitch auth warning
      const detectBrowser = (): BrowserType => {
        const userAgent = navigator.userAgent.toLowerCase();
        
        if (userAgent.indexOf('chrome') > -1 && userAgent.indexOf('edg') === -1) {
          return 'chrome';
        } else if (userAgent.indexOf('firefox') > -1) {
          return 'firefox';
        } else if (userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1) {
          return 'safari';
        } else if (userAgent.indexOf('edg') > -1) {
          return 'edge';
        } else if (userAgent.indexOf('trident') > -1) {
          return 'ie';
        } else if (userAgent.indexOf('opr') > -1 || userAgent.indexOf('opera') > -1) {
          return 'opera';
        } else {
          return 'other';
        }
      };

      const browser = detectBrowser();
      setBrowserType(browser);
      setIsBrowserDetected(true);
    }
  }, []);

  return { 
    browserType, 
    isChrome: browserType === 'chrome',
    isBrowserDetected,
    hasWarningBeenDismissed
  };
};

export default useBrowserDetection; 