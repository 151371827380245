import React from 'react';

interface FeatureCardProps {
  imageUrl?: string;
  backgroundColor?: string;
  className?: string;
}

// Image-only feature card for top section
const FeatureCard: React.FC<FeatureCardProps> = ({ backgroundColor, className, imageUrl }) => {
  return (
    <div 
      className={`rounded-3xl p-6 sm:p-8 flex flex-col border border-[#2C2C2C] ${className} relative overflow-hidden h-full`}
      style={{ minHeight: '250px' }}
    >
      {/* Background */}
      <div className="absolute inset-0 w-full h-full">
        <div 
          className="absolute inset-0 w-full h-full"
          style={{
            backgroundImage: `url('/images/frame-26.png')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            filter: 'brightness(0.85) contrast(1.1)',
            mixBlendMode: 'multiply',
          }}
        />
        <div className="absolute inset-0 bg-gradient-to-t from-transparent to-black opacity-60"></div>
      </div>
      
      {/* Image container */}
      {imageUrl && (
        <div className="relative z-10 w-full h-full flex items-center justify-center">
          <div className="w-full h-full max-w-[90%]">
            <img 
              src={imageUrl} 
              alt="Feature illustration" 
              className="w-full h-full object-contain drop-shadow-lg rounded-lg border border-[#2C2C2C]"
            />
          </div>
        </div>
      )}
    </div>
  );
};

const Features: React.FC = () => {
  return (
    <div className="w-full bg-transparent pt-12 px-4 text-left">
      <div className="max-w-7xl mx-auto">
        {/* Top section - 3 full-width cards stacked vertically with headings above */}
        <div className="flex flex-col gap-12 mt-16 mb-12">
          {/* First feature */}
          <div>
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-4 text-center">
              Live Highlights
            </h2>
            <p className="text-xl text-[#dddddd] mb-10 mt-6 text-center">
              One-time Twitch Bot setup. Get highlights in real time. Messages Twitch Chat or Discord.
            </p>
            <div className="relative">
              <div 
                className="absolute inset-0 w-full h-full"
                style={{
                  backgroundImage: `url('https://storage.googleapis.com/saved_media_assets/landing-texture-1.avif')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '30px'
                }}
              ></div>
              <FeatureCard 
                backgroundColor="linear-gradient(to right, #0B0B0C, #1a1a1a)"
                className="w-full relative z-10"
                imageUrl="https://storage.googleapis.com/saved_media_assets/live-page-preview.avif"
              />
            </div>
          </div>
          
          {/* Second feature */}
          <div>
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-4 text-center mt-14">
              Converters
            </h2>
            <p className="text-xl text-[#dddddd] mb-10 mt-6 text-center">
              Turn old streams and clips into edited highlights.
            </p>
            <div className="relative">
              <div 
                className="absolute inset-0 w-full h-full"
                style={{
                  backgroundImage: `url('https://storage.googleapis.com/saved_media_assets/landing-texture-2.avif')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '30px'
                }}
              ></div>
              <FeatureCard 
                backgroundColor="linear-gradient(to right, #0B0B0C, #1a1a1a)"
                className="w-full relative z-10"
                imageUrl="https://storage.googleapis.com/saved_media_assets/streams-preview.avif"
              />
            </div>
          </div>
          
          {/* Third feature */}
          <div>
            <h2 className="text-4xl md:text-5xl font-bold text-white mb-4 text-center mt-14">
              Customize
            </h2>
            <p className="text-xl text-[#dddddd] mb-10 mt-6 text-center">
              Fine-tune every aspect of your highlights with powerful editing tools.
            </p>
            <div className="relative">
              <div 
                className="absolute inset-0 w-full h-full"
                style={{
                  backgroundImage: `url('https://storage.googleapis.com/saved_media_assets/landing-texture-3.avif')`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '30px'
                }}
              ></div>
              <FeatureCard 
                backgroundColor="linear-gradient(to right, #0B0B0C, #1a1a1a)" 
                className="w-full relative z-10"
                imageUrl="https://storage.googleapis.com/saved_media_assets/editor-preview.avif"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;