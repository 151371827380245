import { Testimonial } from '../lib/api/types';

export const heroTestimonials: Testimonial[] = [
  {
    id: 'hero-1',
    name: 'stubbornJoel',
    company: 'Twitch',
    quote: "thank you so much, you brought something really cool, that works pretty good and it's free, it's hugely impressive how you're offering something even the biggest enterprises of AI couldn't make work this good so thank you for that side.",
    avatar: '',
  },
  {
    id: 'hero-2',
    name: 'Mr. SilentChaos',
    company: 'Twitch',
    quote: "This tool is the definitive way to quickly curate, prep, and do your short form content on YouTube and twitch. Let it do the work and you worry about being yourself on stream!",
    avatar: '',
  },
  {
    id: 'hero-3',
    name: 'YoghurtThese9544',
    company: 'Reddit',
    quote: "As a user who has tried many automated solutions, I can vouche that this is the only one that has actually gotten me high quality clips",
    avatar: '',
  },
  {
    id: 'hero-4',
    name: 'juicenoose',
    company: 'Twitch',
    quote: "I was impressed [with] how well it worked for me as a vtuber[,] I was a bit worried it wouldn't pick up some stuff because of that",
    avatar: '',
  },
  {
    id: 'hero-5',
    name: 'shadowscorrupt',
    company: 'Reddit',
    quote: "i suck at making clips. But this made me feel pretty good about my own clips. thanks",
    avatar: '',
  },
  {
    id: 'hero-6',
    name: 'hacksarplays',
    company: 'Reddit',
    quote: "This is so amazing, you're wonderful. 😭",
    avatar: '',
  },
  {
    id: 'hero-7',
    name: 'Matt',
    company: 'Project Sparrow',
    quote: "It's been a real time investment streaming, getting clips, then editing them etc... so thank you so much for making a tool like this! It helps us market our game a lot easier!",
    avatar: '',
  },
  {
    id: 'hero-8',
    name: 'Khaiden Tajigen',
    company: 'Twitch',
    quote: "I finally got a stream's highlights processed properly and they look really promising, I'm loving this already!",
    avatar: '',
  }
]; 